#TypeOfWork .zsBtn {
  margin-left: 20px;
}
#TypeOfWork .zsBtn button {
  height: 30px;
}
#TypeOfWork .iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
#TypeOfWork .form-box .el-form {
  width: 90%;
}
